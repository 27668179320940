(function ($, generic) {
  Drupal.behaviors.emailSignupPanelV1 = {
    attach: function (context) {
      var self = this;
      var $template = $('.js-email-signup-panel-v1', context);

      if ($template.length < 1) {
        return null;
      }

      $template.each(function () {
        var $emailContainerNode = $(this);
        var $emailForm = $('.js-email-signup-panel__form', $emailContainerNode);
        var $emailInput = $('.js-email-signup-panel__email-field', $emailContainerNode);
        var $mobileNumberInput = $('.js-email-signup-panel__sms-field', $emailContainerNode);
        var $emailErrorMessage = $('.js-email-signup-panel-error', $emailContainerNode);
        var $emailSuccessMessage = $('.js-email-signup-panel-success', $emailContainerNode);
        var $emailAlreadySignedUpMessage = $(
          '.js-email-signup-panel-already-signed-up',
          $emailContainerNode
        );
        var $gdprTextLink = $('.js-email-signup-panel__information', $emailContainerNode);
        var $closeIcon = $('.js-email-signup-panel__close-btn', $emailContainerNode);
        var $gdprOverlay = $('.js-email-signup-panel__gdpr-overlay', $emailContainerNode);
        var $smsTermsAndConditions = $(
          '.js-email-signup-panel__sms-terms-conditions',
          $emailContainerNode
        );
        var $submitButton = $('.js-email-signup-panel__button', $emailContainerNode);
        var $descriptionLabel = $('.js-email-signup-panel__description', $emailContainerNode);
        var $termsAndConditions = $(
          '.email-signup-panel__terms-and-conditions',
          $emailContainerNode
        );
        var $emailPromotions = $('.js-email-signup-panel-email-promotions', $emailContainerNode);
        var privacyPolicyTooltip = {
          $label: $('.js-privacy-policy-tooltip', $emailContainerNode),
          $text: $('.js-privacy-policy-tooltip-text', $emailContainerNode),
          $arrow: $('.js-privacy-policy-tooltip-text-arrow', $emailContainerNode)
        };

        function toggleElement($elem) {
          $elem.slideDown(300, function () {
            $elem.removeClass('hidden');
          });
        }
        // Show submit button
        $emailInput.off('focus').on('focus', function () {
          toggleElement($submitButton);
          toggleElement($mobileNumberInput);
        });
        // Show terms + conditions
        $mobileNumberInput.off('focus').on('focus', function () {
          toggleElement($smsTermsAndConditions);
        });
        // Hide terms + conditions when clicked outside
        $('html')
          .once('terms-condition-hide')
          .on('click', function (hideEvt) {
            if (
              !$(hideEvt.target).closest($emailForm).length &&
              !$(hideEvt.target).is($emailForm)
            ) {
              if ($smsTermsAndConditions.is(':visible')) {
                $smsTermsAndConditions.slideUp(300, function () {
                  $smsTermsAndConditions.addClass('hidden');
                });
              }
            }
          });

        $gdprTextLink.once().on('click.gdrpLink', function (event) {
          var expanded = $gdprOverlay.hasClass('expanded');

          event.preventDefault();
          $gdprOverlay.toggleClass('expanded', !expanded);
          if (!expanded) {
            $gdprOverlay.trigger('focus');
          }
        });

        $closeIcon.once().on('click.gdprClose', function (event) {
          event.preventDefault();
          $gdprOverlay.toggleClass('expanded', false);
          $gdprTextLink.focus();
        });

        $emailForm.once().submit(function (submitEvt) {
          submitEvt.preventDefault();
          $emailSuccessMessage
            .add($emailErrorMessage)
            .add($emailAlreadySignedUpMessage)
            .addClass('hidden');
          $emailInput.removeClass('error');
          $mobileNumberInput.removeClass('error');
          // Transform string into array of form elements
          var params = {};

          $.each($emailForm.serializeArray(), function (index, kv) {
            params[kv.name] = kv.value.replace('undefined', '').replace('%40', '@');
          });
          // Handle the sms_promotions checkbox - if its checked its being read as 'on' when serialized
          if (
            $emailForm.find('input[name="SMS_PROMOTIONS"]').prop('checked') &&
            $mobileNumberInput.val() !== ''
          ) {
            params['SMS_PROMOTIONS'] = 1;
          }
          // @todo Please, remove this section after SDCOMM-2924 goes to PROD.
          if ($.cookie('csrftoken')) {
            params['_TOKEN'] = $.cookie('csrftoken');
          }
          // Send the data via a json rpc call
          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [params],
            onSuccess: function (jsonRpcResponse) {
              var response = jsonRpcResponse.getData();
              var alreadySignedUp = response.userinfo.previously_opted_in_email_promotion;

              if (alreadySignedUp) {
                $(document).trigger('email_signup:success_but_signed_up');
                $emailAlreadySignedUpMessage.removeClass('hidden');
              } else {
                $(document).trigger('email_signup:success_and_new');
                $submitButton.addClass('hidden');
                $smsTermsAndConditions.addClass('hidden');
                $termsAndConditions.addClass('hidden');
                $descriptionLabel.addClass('hidden');
                $mobileNumberInput.addClass('hidden');
                $emailInput.addClass('hidden');
                $emailSuccessMessage.removeClass('hidden');
                $emailPromotions.addClass('hidden');
              }
              $emailSuccessMessage.removeClass('hidden');
            },
            onFailure: function (jsonRpcResponse) {
              var errorObjectsArray = jsonRpcResponse.getMessages();
              var errorLength = errorObjectsArray.length;
              var errors = '';

              generic.showErrors(errorObjectsArray, null, null);
              $emailErrorMessage.removeClass('hidden');
              for (var i = 0; i < errorLength; i++) {
                var myErr = errorObjectsArray[i];

                if (myErr && myErr.key) {
                  if (
                    myErr.key === 'required.pc_email_address.email_signup' ||
                    myErr.key === 'invalid.pc_email_address.email_signup' ||
                    myErr.key === 'required.pc_email_promotions.email_sms_signup' ||
                    myErr.key === 'required.pc_email_address.email_sms_signup'
                  ) {
                    errors += myErr.text + '<br/>';
                    $emailInput.addClass('error').focus();
                  }
                  if (
                    myErr.key ===
                      'required_dependency.sms_promotions.mobile_phone.dependency.sms' ||
                    myErr.key === 'format.mobile_phone.sms' ||
                    myErr.key === 'min_max.mobile_phone.sms' ||
                    myErr.key ===
                      'required_and.sms_promotions.mobile_phone.required_and.email_sms_signup'
                  ) {
                    errors += myErr.text + '<br/>';
                    $mobileNumberInput.addClass('error').focus();
                  }
                  if (
                    myErr.key === 'required_dependency.validate_terms.terms.dependency.email_signup'
                  ) {
                    errors += myErr.text + '<br/>';
                  }
                }
              }
              if (errors) {
                $emailErrorMessage.html(errors);
              } else {
                $emailErrorMessage.removeClass('hidden');
              }
            }
          });
        });

        if (privacyPolicyTooltip.$label.length > 0 && privacyPolicyTooltip.$text.length > 0) {
          privacyPolicyTooltip.$label.addClass('privacy-policy-tooltip').append(privacyPolicyTooltip.$text);
          self.updatePrivacyPolicyTooltipPosition(privacyPolicyTooltip);

          $(window).on(
            'resize',
            _.debounce(function () {
              self.updatePrivacyPolicyTooltipPosition(privacyPolicyTooltip);
            }, 100)
          );
        }
      });
    },
    updatePrivacyPolicyTooltipPosition: function (privacyPolicyTooltip) {
      var tooltipOffset = privacyPolicyTooltip.$label.position().left;
      var tooltipParentWidth = privacyPolicyTooltip.$label.parent().width();
      var tooltipLabelWidth = privacyPolicyTooltip.$label.width();
      var tooltipArrowWidth = privacyPolicyTooltip.$arrow.get(0).getBoundingClientRect().width;

      // Position the tooltip text to match the parent width and alignment. Does not need RTL.
      privacyPolicyTooltip.$text.css({
        left: -tooltipOffset,
        width: tooltipParentWidth
      });
      // Position the tooltip arrow to the middle of the label element. Does not need RTL.
      privacyPolicyTooltip.$arrow.css({
        left: tooltipOffset + tooltipLabelWidth / 2 - tooltipArrowWidth / 2
      });
    }
  };
})((window.jQuery = window.jQuery || function () {}), (window.generic = window.generic || {}));
